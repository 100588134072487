.sidebar-modal {
	position: absolute;
	top: 0;
	left: 0;
	width: fit-content;
	height: fit-content;
	transform: translate(calc(-100% - 1.2em), 0);
	z-index: 1001;
	border-radius: var(--border-radius);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	background-color: var(--tertiary-bg-color);
	padding: 0.5em;
	color: var(--font-color);
	font-weight: 400;
}

.sidebar-modal::before {
	/* this is here so that the mouseevent still gets triggered when hover outside of the arrow (::after element) */
	content: "";
	position: absolute;
	width: 2rem;
	height: 70%;
	top: 0;
	right: 0;
	transform: translate(100%, 0);
}

.sidebar-modal::after {
	content: "";
	width: 0;
	height: 0;
	border-top: 0.5em solid transparent;
	border-left: 0.7em solid var(--tertiary-bg-color);
	border-bottom: 0.5em solid transparent;
	transform: translate(100%, 1.5em);
	position: absolute;
	top: 0;
	right: 0;
}

.sidebar-modal li {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding: 0.5em 1em;
	white-space: nowrap;
	cursor: pointer;
}

.sidebar-modal li:last-child {
	border-bottom: 0;
}
