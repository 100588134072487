.loading {
	width: 100dvw;
	height: 100dvh;
	/* position: fixed; */
	position: relative;
	bottom: 0;
	z-index: 1;
	background-color: var(--header);
}

.loading video {
	/* aspect ratio is 21:9 */
	height: 200px;
	position: absolute;
	box-shadow: 0 0 20px var(--header);
	opacity: 0.9;
	transform: translate(0, -20%);
	border-radius: 50px;
	z-index: 2;
}
