.dropdown {
	position: relative;
	width: fit-content;
	min-width: 18em;
	margin-left: 1.25%;
	border-radius: 7px;
	background-color: var(--secondary-bg-color);
}

.dropdown-header {
	cursor: pointer;
	display: flex;
	justify-content: left;
	align-items: center;
	z-index: 10001;
	padding: 0.7em;
	border-radius: 7px;
	border: 1px solid var(--secondary);
}

.dropdown-header .header-prefix {
	margin-right: 0.25em;
}

/* ---------------------------------------------- */

.icon-dropdown {
	font-size: 1em;
	color: var(--secondary);
	transform: rotate(0deg);
	transition: all 0.2s ease-in-out;
	margin-left: auto;
}

.icon-dropdown.open {
	transform: rotate(90deg);
}

/* ---------------------------------------------- */

.dropdown-body {
	background-color: var(--secondary-bg-color);
	width: 100%;
	display: none;
	position: absolute;
	top: calc(2.5em - 0.6em);
	right: 0;
	z-index: 10000;
	border-radius: 0 0 7px 7px;
	padding: 0;
	max-height: 13em;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid var(--secondary);
	border-top: none;
	margin-top: 0.5em;
}

.dropdown-body::-webkit-scrollbar {
	width: 0.2em;
}

.dropdown-body::-webkit-scrollbar-thumb {
	background-color: var(--secondary);
}

.dropdown-body.open {
	display: block;
}

.dropdown-item {
	margin: 0 0.5em;
	padding: 0.6em 0.1em;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: center;
}

.dropdown-item:first-child {
	margin-top: 0.6em;
}

.dropdown-item:last-child {
	margin-bottom: 0.25em;
}

.dropdown-item:hover {
	cursor: pointer;
}

.dropdown-item span {
	pointer-events: none;
}

.dropdown-item.active {
	background-color: var(--bg-color);
	filter: var(--active-filter);
}

/* --------------------------- */

.checkbox-dropdown {
	position: relative;
}

.checkbox-dropdown::after {
	content: "";
	position: absolute;
	top: 0;
	left: calc(var(--label-width) * -0.1);
	width: var(--label-width);
	height: 100%;
	cursor: pointer;
}
