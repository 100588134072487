.modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1000;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.modal-main {
	position: absolute;
	background: var(--secondary-bg-color);
	box-shadow: 0 0 10px var(--tertiary), 0 0 1px navy;
	width: clamp(350px, 50dvw, 50dvw);
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	padding: 2em;
	z-index: 1000;
}

.modal-main p {
	padding: 1.5em 0;
	white-space: pre-line;
	line-height: 1.5;
	width: fit-content;
	margin: 0 auto;
}

.modal-main .icon {
	font-size: 1.5em;
}

.modal-main #modal-message {
	margin: 2rem 0;
}

.modal-main #modal-close {
	position: absolute;
	top: 0;
	right: 0;
	margin: 1em;
	border: none;
	background: none;
}

.modal-main #modal-close .icon {
	color: var(--tertiary);
}

.modal-main #modal-close:hover .icon {
	color: var(--font-color);
}

.modal-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.modal-input button {
	display: flex;
	border-radius: 8px;
	border: none;
	height: 3em;
	width: 6em;
	align-items: center;
	justify-content: space-evenly;
	color: white;
	font-size: inherit;
}

.modal-input button .icon {
	color: white;
}

.modal-input button#modal-submit {
	background-color: limegreen;
}

.modal-input button#modal-submit .icon {
	font-size: 1.4em;
}

.modal-input button#modal-submit {
	background-color: forestgreen;
}

.modal-input button#modal-cancel {
	background-color: firebrick;
}

/* ------------------------------------ */

.modal-main.exact-fit {
	padding: 0;
	width: fit-content;
}

.modal-main.exact-fit #modal-message {
	margin: 0;
}

/* ------------------------------------ */

.pulse {
}

.pulse::before,
.pulse::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	border-radius: 8px;
	opacity: 0.7;
	z-index: -1;
}

.pulse.warning::before {
	animation: pulse-red 2s ease-out infinite;
}

.pulse.warning::after {
	animation: pulse-red 2s 1.5s ease-out infinite;
}

.pulse.alert::before {
	animation: pulse-yellow 2s ease-out infinite;
}

.pulse.alert::after {
	animation: pulse-yellow 2s 1.5s ease-out infinite;
}

.pulse.notification::before {
	animation: pulse-blue 2s ease-out infinite;
}

.pulse.notification::after {
	animation: pulse-blue 2s 1.5s ease-out infinite;
}

.pulse.success::before {
	animation: pulse-green 2s ease-out infinite;
}

.pulse.success::after {
	animation: pulse-green 2s 1.5s ease-out infinite;
}

@keyframes pulse-red {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	80% {
		box-shadow: 0 0 0 20px rgba(255, 82, 82, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-yellow {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 223, 82, 0.6);
	}

	80% {
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-blue {
	0% {
		box-shadow: 0 0 0 0 rgba(82, 232, 255, 0.7);
	}

	80% {
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-green {
	0% {
		box-shadow: 0 0 0 0 rgba(82, 255, 122, 0.7);
	}

	80% {
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
