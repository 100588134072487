.icon.switch {
}

.switch input {
	height: 0;
	width: 0;
	visibility: hidden;
}

.dark-mode-switch-label {
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 15px;
	background: grey;
	border-radius: 48px;
	position: relative;
}

.dark-mode-switch-label .dark-mode-switch-button {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 28px;
	height: 28px;
	transform: translate(-1px, -20%);
	border-radius: 50%;
	transition: 0.2s;
	background: var(--secondary);
	box-shadow: 0 0 3px 0 rgba(10, 10, 10, 0.29);
}

.switch input:checked + .dark-mode-switch-label .dark-mode-switch-button {
	left: 16px;
	transform: translate(0, -20%);
}
