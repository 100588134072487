.side-bar {
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: width 0.3s ease-in-out;
	height: calc(100dvh - var(--header-height));
	position: absolute;
	right: 0;
	top: var(--header-height);
	border-left: 1px solid rgba(128, 128, 128, 0.5);
	color: var(--secondary);
	background-color: var(--header);
	z-index: 10000;
}

.side-bar.nav-display {
	width: 10em;
}

.side-bar.nav-hide {
	width: 0;
}

.side-bar.nav-hide #dark-mode-switch-container * {
	/* bottom section sidebar layour jumps a lot without this */
	width: 0;
	height: 1.2em;
	opacity: 0;
}

.side-bar section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100% - 2em);
}

.side-bar section .fr {
	justify-content: space-between;
	width: 100%;
}

.side-bar h5 {
	color: inherit;
	font-weight: 400;
	font-size: 0.9em;
	margin: 0;
	margin-right: 1em;
	white-space: nowrap;
}

.side-bar .icon {
	margin: 0.7em 0;
	color: inherit;
}

.side-bar section.bottom {
	margin-top: auto;
}

#nav-toggle-container {
	align-self: flex-end;
	padding: 0 0.75em;
	margin: 1em 0;
}

#nav-toggle-container #nav-toggle {
	border: none;
	background-color: transparent;
}

#nav-toggle-container .icon {
	margin: 0;
	padding: 0.2em 0.1em;
	color: var(--secondary);
	font-size: 1.4rem;
}

.side-bar.nav-hide #nav-toggle-container .icon {
	color: var(--tertiary);
}

.side-bar #emergency-button:hover {
	color: red;
}

.side-bar #dark-mode-switch-container {
	align-self: flex-end;
	transition: opacity 0.3s ease-in-out;
}

.side-bar.nav-display #dark-mode-switch-container {
	opacity: 1;
}

.side-bar.nav-hide #dark-mode-switch-container {
	opacity: 0;
	pointer-events: none;
}
