.header {
	height: var(--header-height);
	padding: 0.5dvh;
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: var(--header);
	z-index: 1;
}

.logo {
	height: 100%;
	justify-self: left;
	align-self: left;
}

.logo img {
	height: 100%;
	width: 100%;
}
