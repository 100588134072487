.drone-list {
	align-self: start;
	width: 100%;
	margin: 1em 0;
}

.drone-list ul {
	height: 9em;
	overflow-y: scroll;
	overflow-x: hidden;
}

.drone-list ul::-webkit-scrollbar {
	width: 0.2em;
}

.drone-list ul::-webkit-scrollbar-thumb {
	background-color: var(--secondary);
}

.drone-list li {
	justify-content: space-between;
}

.drone-list .drone-list-item:last-child {
	padding-bottom: 3rem;
}

.drone-list li .drone-fieldset-wrapper input {
	margin-right: 0.5em;
}

.drone-list li .drone-fieldset-wrapper #drone-weight-input {
	padding: 0.7em;
	margin-right: 0.2em;
}

.drone-list button {
	background-color: transparent;
	border: none;
	aspect-ratio: 1 / 1;
	font-size: 1ch;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	margin: 0.7rem;
}

.drone-list button .icon {
	transition: all 0.2s ease-in-out;
	color: var(--secondary);
}

.drone-list .add-btn:hover {
	background-color: var(--login-btn);
}

.drone-list .add-btn:hover .icon {
	color: var(--icon-color);
}

.drone-list .add-btn {
	background-color: var(--secondary-bg-color);
	border: 1px solid var(--secondary);
	margin-top: 2em;
	border-radius: 50%;
}


.drone-list .add-btn:hover {
	scale: 1.05;
}

.drone-list li * {
	margin-bottom: 0;
}

.drone-list li:last-of-type {
	margin-bottom: 0.25em;
}