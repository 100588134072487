.sub-section.login.drone-reg {
	width: clamp(24rem, 30dvw, 38rem);
}

.sub-section.drone-reg form {
	width: 100%;
}

.drone-reg form h1 {
	align-self: start;
	margin: 0.8em 0;
	margin-top: 1.2em;
	position: relative;
}

.drone-reg #dronereg-back-to-signin-btn {
	position: absolute;
	right: 0;
	top: 0;
	margin: 1rem;
	padding: 0;
}

.drone-reg #drone-reg-info {
	text-align: left;
}

.drone-reg input,
.drone-reg button {
	padding: 0.7em;
	margin: 0.7em 0;
	width: fit-content;
	border-radius: 7px;
}

.drone-reg input:focus {
	outline-width: 0;
}

.drone-reg button.submit {
	background-color: var(--login-btn);
	color: var(--icon-color);
	border: none;
	cursor: pointer;
	margin-top: 0;
}

.drone-reg p {
	font-size: 0.8em;
	font-weight: 500;
	color: var(--tertiary);
}

.drone-reg p button {
	background-color: inherit;
	font-size: inherit;
	border: none;
	padding: 0;
	font-weight: 600;
	color: var(--tertiary);
	cursor: pointer;
}

.drone-reg #unitID-reg-dropdown {
	align-self: start;
	font-size: 0.9em;
	font-weight: 400;
	margin-left: 0;
}

.drone-reg #unitID-reg-dropdown .dropdown-item .item-body {
	margin: 0 0.7em;
	margin-right: auto;
	color: var(--font-color);
}
