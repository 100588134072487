@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Orbitron&display=swap");

#root {
	height: 100dvh;
	width: 100dvw;

	overflow: hidden;
	background-color: #152b4f;
	--hover-duration: 400ms;
	--hover-delay: 100ms;
	--active-filter: contrast(1.05) brightness(1.05);
	--inactive-filter: grayscale(0.4);

	--header-height: 7dvh;
	--header: #152b4f;
	--login-btn: #3b558f;
	--sect-gap: 1rem;
	--subsect-padding: 1rem;
	--card-margin: 0.5rem;
	--circle-btn-width: 2rem;
	--border-radius: 4px;
}

[data-theme="dark"] {
	--bg-color: #152b4f;
	--secondary-bg-color: #0c1d41;
	--font-color: white;
	--icon-color: white;
	--primary: #b0d0ed;
	--secondary: #b9d2e9;
	--tertiary: #adbdce;
	--tertiary-bg-color: #272f38;
	--clock: #c9c3ed;
	--shadow: rgba(168, 202, 234, 0.2);
}

[data-theme="light"] {
	--bg-color: #d9e6f6;
	--secondary-bg-color: #ffffff;
	--font-color: #1b3968;
	--icon-color: white;
	--primary: #b0d0ed;
	--secondary: #96a5b5;
	--tertiary: #6d7073;
	--tertiary-bg-color: #ffffffec;
	--clock: #392a9a;
	--shadow: rgba(46, 48, 78, 0.2);
}

* {
	box-sizing: border-box;
}

html {
	height: 100dvh;
	width: 100dvw;
	overflow: hidden;
	background-color: var(--header);
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

button,
input {
	font-family: "Montserrat", sans-serif;
}

button {
	border: none;
	background: none;
}

input[type="number"] {
	width: 4.5em;
	padding: 0.4em 0.3em;
	margin: 0.4em 0;
	display: block;
	border: 1px solid var(--secondary);
	border-radius: 5px;
	font-size: 1em;
	text-align: center;
}

input[type="number"]::-webkit-inner-spin-button {
	margin: 0 0 0 0.1em;
}

label {
	font-size: 0.9em;
	font-weight: bold;
}

.App {
	text-align: center;
	height: 100dvh;
	width: 100dvw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: clamp(0.9rem, 1.2dvw, 1.5rem);
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	color: var(--font-color);
	background-color: var(--header);
	cursor: default;
	position: relative;
}

/* ----------------------------------------------------------------------------- */
.body {
	width: 100%;
	height: 100%;
	position: relative;
	margin-bottom: auto;
}

.body-scroll {
	overflow-y: scroll;
	overflow-x: hidden;
	background: var(--bg-color);
}

.flex-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* width: 95%; */
	margin: 0 auto;
}

.flex-main .sub-section:first-child {
	margin-top: 0;
}

.section {
	width: 95%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	height: fit-content;
}

.grid section {
	padding: calc(var(--sect-gap) / 2);
	border-radius: var(--border-radius);
	gap: calc(var(--sect-gap) / 2);
	border: 1.5px solid var(--secondary-bg-color);
	position: relative;
}

.sub-section {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: var(--subsect-padding);
	border-radius: var(--border-radius);
	/* width: 100%; */
	background-color: var(--secondary-bg-color);
	position: relative;
	/* box-shadow: 0 0 7px var(--shadow); */
}

.sub-section.misc {
	flex-direction: column;
}

.chart-container .my-chart {
	height: clamp(250px, 50dvh, 50dvh);
}

.zoomIn {
	animation: 0.5s ease-in-out forwards zoomIn;
	transform: scale(0.92);
}

@keyframes zoomIn {
	100% {
		transform: scale(1);
	}
}

.abs-bottom {
	position: absolute;
	bottom: 0;
}

.sub-section h6.abs-bottom {
	font-weight: 400;
	font-size: 0.7em;
	font-style: italic;
	padding: 1em 2em;
	margin: 0;
	left: 0;
}

.card {
	margin: 8px;
	/* padding: 8px; */
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.card h3 {
	margin: 0.5em;
}

.card h4 {
	margin: 0;
	font-weight: 500;
}

.card h5 {
	margin: 0;
	font-weight: 400;
	font-size: 0.8em;
}

.overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

/* ----------------------------------------------------------------------------- */

.icon {
	font-size: 1.5em;
	opacity: 0.9;
	color: var(--secondary);
	transition: color var(--hover-duration) ease-out;
}

/* button:hover .icon,
button:active .icon {
	color: var(--font-color);
} */

.clickable {
	transition: transform 200ms ease-out;
}

.clickable:hover {
	transform: scale(1.03);
	cursor: pointer;
}

.clickable:active {
	transform: scale(1.03);
}

button.submit,
button.reset {
	border-radius: 10px;
	color: white;
	padding: 0.3em 0.4em;
	margin-left: 0.5em;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

button.submit h4,
button.reset h4 {
	font-weight: 400;
	margin: 0;
}

button.submit {
	border: none;
	background-color: limegreen;
}

button.reset {
	border: 1px solid lightgray;
	color: var(--font-color);
	background-color: var(--tertiary-bg-color);
}

button.submit .icon,
button.reset .icon {
	margin-left: 0.3em;
	padding: 0;
	font-size: 1.1em;
	color: inherit;
}

button.submit .icon:hover,
button.submit .icon:active,
button.reset .icon:hover,
button.reset .icon:active {
	color: inherit;
}

/* --------------------------------------------------------------- */
::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--tertiary);
	border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.fc {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.fr {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

@keyframes rotateR {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
