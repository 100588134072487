.circuit {
	position: absolute;
	z-index: 0;
	height: 100%;
	width: 100%;
	background-color: var(--circuit-bg);
}

.circuit svg {
	z-index: 0;
	height: 100%;
	display: flex;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: var(--circuit-opacity);
}

.circuit polyline {
	opacity: 0.5;
	stroke: var(--circuit-line);
}

.circuit g {
	stroke: var(--circuit-line);
}

.path {
	animation: draw var(--circuit-anim-duration) infinite;
	animation-timing-function: linear;

	stroke: var(--circuit-line);
	/* stroke-dasharray: 1000; */
}

.path-01 {
	animation-delay: 0s;
}

.path-02 {
	animation-delay: 0.2s;
}

.path-03 {
	animation-delay: 0.4s;
}

.path-04 {
	animation-delay: 0.6s;
}

.path-05 {
	animation-delay: 0.8s;
}

@keyframes draw {
	0% {
	}

	100% {
		stroke-dashoffset: 0;
		stroke-opacity: 1;
	}
}
