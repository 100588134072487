article.login {
	height: 100%;
	justify-content: flex-start;
}

article.login section {
	height: 90%;
	width: 100%;
}

.sub-section.login {
	width: clamp(22rem, 25dvw, 30em);
	height: fit-content;
	max-height: fit-content;
	padding: 2em;
	margin: 0;
	box-shadow: inset 0 0 0 3px var(--login-btn);
	border: 0;
	border-radius: 7px;
	position: relative;
	transform-style: preserve-3d;
	background-color: var(--bg-color);
}

.sub-section.login::before,
.sub-section.login::after {
	content: "";
	position: absolute;
	top: 0;
	left: -1;
	height: 100%;
	width: 100%;
	transform-origin: center;
	border-radius: 7px;
	opacity: 0.6;
	transition: transform 0.6s;
}

.sub-section.login::before {
	border-top: 1px solid var(--secondary-bg-color);
	border-bottom: 1px solid var(--secondary-bg-color);
	transform: scaleX(0);
	z-index: -1;
}

.sub-section.login::after {
	border-left: 1px solid var(--secondary-bg-color);
	border-right: 1px solid var(--secondary-bg-color);
	transform: scaleY(0);
	z-index: -1;
}

.sub-section.login:hover::before,
.sub-section.login:hover::after {
	transform: scaleX(1);
	transform: scaleY(1);
}

.sub-section.login main {
	width: 100%;
}

.login main h1 {
	align-self: start;
	margin: 0.8em 0;
}

.login input,
.login button.submit {
	padding: 0.7em;
	margin: 0.7em 0;
	width: 100%;
	border-radius: 7px;
	border: 1px solid var(--secondary);
	background-color: var(--icon-color);
}

.login input:focus {
	outline-width: 0;
}

.login button.submit {
	background-color: var(--login-btn);
	color: var(--icon-color);
	margin-top: 2.5em;
	border: none;
	cursor: pointer;
}

.login button#password-forgot {
	background-color: inherit;
	border: none;
	color: var(--font-color);
	font-size: 0.8rem;
	position: absolute;
	right: 0;
	bottom: 0;
	transform: translate(0, 50%);
	cursor: pointer;
}

.login footer {
	font-size: 0.8em;
	font-weight: 500;
	color: var(--tertiary);
	margin: 1em;
}

.login footer button {
	background-color: inherit;
	font-size: inherit;
	border: none;
	padding: 0;
	font-weight: 600;
	color: var(--tertiary);
	cursor: pointer;
	/* text-decoration: underline;
    text-underline-offset: 0.2em */
}

.login .error {
	border: 1.5px solid red;
}

.password-wrapper {
	position: relative;
	width: 100%;
}

.password-wrapper::before {
	content: "";
	color: red;
	position: absolute;
	transform: translate(0.5em, -50%);
	top: 0;
	left: 0;
	font-size: 0.7em;
}

.password-wrapper .no-match::before {
	content: "Passwords do not match";
	color: red;
	position: absolute;
	transform: translate(0.5em, -50%);
	top: 0;
	left: 0;
	font-size: 0.7em;
}

.password-wrapper .invalid::before {
	content: "Min. 8 characters with at least one number, one uppercase and lowercase letter.";
	color: red;
	position: absolute;
	transform: translate(0.5em, 50px);
	top: 0;
	left: 0;
	font-size: 0.7em;
	text-align: left;
}

.password-show-icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(-50%, -50%);
	color: var(--tertiary);
	cursor: pointer;
}

/* ------------------------------- */

.login #modal-message {
	font-size: 1em;
	font-weight: 400;
	/* color: var(--font-color) */
}

/* ------------------------------- */

#otp-back-to-signin-btn {
	position: absolute;
	right: 0;
	top: 0;
	margin: 1rem;
}
