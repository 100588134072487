.autocomplete-search-container {
	position: relative;
	margin-right: 0.5em;
	width: 12em;
}

.autocomplete-search-container * {
	font-size: 0.9em;
}

.autocomplete-search-container .search-box {
	width: inherit;
}

.autocomplete-search-container .options {
	font-weight: 400;
	position: absolute;
	background-color: var(--secondary-bg-color);
	top: calc(100% - 1.5rem);
	left: 0;
	z-index: 10000;
	border-radius: 0 0 7px 7px;
	padding: 0.7em;
	padding-top: 0.1em;
	margin: 0.7em 0;
	width: inherit;
	max-height: 7em;
	height: fit-content;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid var(--secondary);
	border-top: none;
	box-shadow: -0.1em 0.1em 0 0.1em #d9e6f6;
}

.autocomplete-search-container .options::-webkit-scrollbar {
	width: 0.1em;
}

.autocomplete-search-container .options::-webkit-scrollbar-thumb {
	background-color: var(--secondary);
}

.options li {
	text-align: left;
	margin: 0.5em 0;
	cursor: pointer;
}

.options .option-active {
	border-bottom: 1px solid var(--secondary);
	font-weight: 500;
	width: fit-content;
}

.no-options {
	display: none;
}