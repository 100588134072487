/* ------------------------------------------------------------------- */
@media all and (max-width: 1280px) {
}

@media all and (max-width: 1024px) {
	.App #dashboard {
		display: grid;
		grid-template-areas:
			"sect1 sect1"
			"sect2 sect2"
			"sect2 sect2";
		grid-template-columns: repeat(2, minmax(18rem, 1fr));
	}

	.App #section-one {
		grid-template-areas: "sect1-1 sect1-2";
		grid-template-columns: none;
		grid-template-rows: none;
	}

	.App .profile {
		grid-template-areas:
			"sect1"
			"sect2"
			"sect3";
		grid-template-columns: minmax(20rem, 1fr);
	}
}

@media all and (max-width: 980px) {
	.sub-section {
		margin: 0.5em;
		/* justify-self: center; */
	}

	.flex-main .chart-container {
		flex-direction: column;
	}

	.chart-container .my-chart {
		width: 80%;
	}

	.body .camera-view {
		flex-direction: column;
	}

	.body .camera-view .sub-section {
		height: fit-content;
	}

	.body .camera-view .sub-section.controls {
		width: 100%;
	}
}

@media all and (max-width: 800px) and (orientation: landscape) {
	/* for the cameraview controls wrapper */
	.sub-section.controls #cam-controls-wrapper {
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
	}
}

@media all and (max-width: 768px) {
	/* HEADER */
	#root .App {
		--header-height: 6dvh;
	}
	.App #section-two {
		grid-template-areas:
			"sect2-1 sect2-2"
			"sect2-1 sect2-3";
		grid-template-columns: min-content minmax(100px, 1fr);
		grid-template-rows: auto;
	}

	.App #dashboard .sub-section.cable,
	.App #dashboard .sub-section.angle {
		flex-direction: column;
		height: fit-content;
	}

	.App #dashboard .sub-section.cable .cablestats-card-wrapper {
		flex-direction: column;
		/* height: fit-content; */
	}

	.App #dashboard .sub-section.angle .my-chart.map-view.simple {
		min-height: 145px;
		height: 200px;
	}

	#selection-menu #rt-indicator p {
		display: none;
	}

	#selection-menu #rt-indicator::before {
		content: "";
	}

	/* FLIGHTLOGS */
	.chart-container .my-chart {
		width: 95%;
	}

	/* SETTINGS */
	.settings .change-pwd-fieldset .password-wrapper input {
		width: 16rem;
	}

	/* PROFILE */
	.profile .flight-time {
		flex-direction: column;
	}

	.App .profile .sub-section {
		height: fit-content;
	}
}

@media all and (max-width: 640px) {
	/* DASHBOARD */
	.App #dashboard {
		grid-template-columns: repeat(1, minmax(18rem, 1fr));
	}

	.App #section-one {
		grid-template-areas:
			"sect1-1"
			"sect1-2";
		grid-template-columns: minmax(190px, 1fr);
	}

	#selection-menu .dropdown {
		min-width: 10em;
		width: 10em;
	}
	#selection-menu .dropdown .dropdown-body .item-body,
	#selection-menu .dropdown .dropdown-header .header-body {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow-x: hidden;
	}

	#selection-menu .dropdown .dropdown-body {
		min-width: fit-content;
		left: 0;
	}
	#selection-menu .dropdown .item-prefix,
	#selection-menu .dropdown .item-suffix {
		display: none;
	}
	#selection-menu .dropdown .icon-dropdown {
		margin-left: 0.5em;
	}

	.btn-container .snapshot-btn:hover::before {
		content: "";
	}

	.btn-container .vid-download-btn:hover::before {
		content: "";
	}

	/* SETTINGS */
	.change-pwd-fieldset .password-wrapper label {
		display: none;
	}

	/* DRONE CONTROLS */

	#drone-controls-container {
		width: inherit;
	}
}

@media all and (max-width: 540px) {
	#drone-controls-container #mini-drone-lps-container {
		display: block;
		height: calc((160px + 2rem) * 3);
	}
	#drone-controls-container #mini-drone-lps-container .card {
		margin-top: 2rem;
		min-height: 160px;
		max-height: 160px;
		height: 160px;
	}
}

@media all and (max-width: 480px) {
	/* HEADER */
	#root .App {
		--header-height: 2.25rem;
	}

	.section .sub-section.cable .cable-settings-wrapper {
		min-width: 140px;
	}

	.cable-settings-wrapper .form-controls {
		flex-direction: column;
		align-items: flex-end;
		margin-top: 1em;
	}

	.cable-settings-wrapper .form-controls button {
		margin: 0;
		margin-top: 0.6em;
	}

	/* SETTINGS */
	.settings .sub-section .change-pwd-fieldset {
		margin: 0;
	}

	.settings .change-pwd-fieldset .password-wrapper input {
		width: 14rem;
	}

	/* DROPDOWN */
	.side-bar #nav-toggle-container {
		padding-left: 0;
		padding-right: 0.25rem;
	}

	#selection-menu #download-container button::before {
		content: "";
	}

	/* DRONE CONTROLS */
	#drone-controls-container #controller-guide-container {
		width: 340px;
	}
}

@media all and (max-width: 420px) {
	/* PROFILE SETTINGS*/
	.body .profile,
	.body .settings.grid {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	/* SELECTION MENU */
	#selection-menu #download-container {
		/* display: none; */
		margin: 0;
	}
}

/* ------------------------------------------------------------------- */
